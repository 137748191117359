<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Container',

  data() {
    return {};
  },

  methods: {
  },
  beforeCreate() {
  },
  beforeMount() {
  },
};
</script>

<style scoped>

</style>
